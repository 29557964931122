import React from "react";

const Service = () => {
  return (
    <div>
      <h1>Service Page</h1>
      <p>Learn more Service us on this page.</p>
    </div>
  );
};

export default Service;
