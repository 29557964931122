// import "./Review.css";

function Review() {
  return (
    <div>
      <h1>Review</h1>
      <p>Learn more about us on this page.</p>
    </div>
  );
}

export default Review;
