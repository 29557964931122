// import "./Gallery.css";

function Gallery() {
  return (
    <div>
      <h1>Gallery</h1>
      <p>Learn more about us on this page.</p>
    </div>
  );
}

export default Gallery;
